
import {
  correctResultColor,
  notify,
  parseDate,
} from "@/core/helpers/globalJaya.ts";
import { computed, defineComponent, ref } from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import CardTitleJaya from "@/components/global/CardTitleJaya.vue";
import { Factor, DiagnosticResult } from "@/store/modules/DiagnosticsModule";

export default defineComponent({
  name: "facteurs",
  emits: ["set-active-factor", "cancel-factor-result"],
  props: {
    factors: { type: Array, required: true },
    diagnostic: { type: Object },
  },
  components: {
    CardTitleJaya,
  },
  setup: function (props, { emit }) {
    const router = useRouter();
    const store = useStore();

    const factorsWithResults = computed(() => {
      interface FactorWithResults {
        factor: Factor;
        result: DiagnosticResult | null;
      }

      let factorWithResults = ref<FactorWithResults[]>([]);
      for (const factor of store.getters.getFactors) {
        if (
          props.diagnostic &&
          props.diagnostic.results.some(
            (result) => result.level_details.factor === factor.id
          )
        ) {
          factorWithResults.value.push({
            factor: factor,
            result: props.diagnostic.results.find(
              (result) => result.level_details.factor === factor.id
            ),
          });
        } else {
          factorWithResults.value.push({ factor: factor, result: null });
        }
      }
      return factorWithResults.value.sort(finishedSorting);
    });

    const resultScore = computed(() => {
      let finalResult = ref<number>(0);
      if (props.diagnostic)
        for (const result of props.diagnostic.results) {
          finalResult.value = finalResult.value + result.level_details.level;
        }
      return Math.round((finalResult.value / (props.factors.length * 5)) * 100);
    });

    const cardSubtitle = computed(() => {
      let cardSubtitle = "";
      if (props.diagnostic) {
        const date_created = parseDate(props.diagnostic.date_created);
        const exploitations = store.getters.getExploitations;
        const exploitationNumber = props.diagnostic.exploitation_id;
        const linkedExploitation = exploitations.find(
          (exploitation) => exploitation.id === exploitationNumber
        );
        cardSubtitle =
          "Diagnostic de '" +
          linkedExploitation.name +
          ", créé le " +
          date_created +
          " (année culturale " +
          new Date(props.diagnostic.date_evaluated).getFullYear() +
          ")";
      }
      return cardSubtitle;
    });

    const scoreOptions = ref({
      series: [35],
      chart: {
        type: "radialBar",
        offsetY: -20,
        sparkline: {
          enabled: true,
        },
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: getCSSVariableValue("--bs-light-dark"),
            strokeWidth: "97%",
            margin: 5, // margin is in pixels
          },
          dataLabels: {
            name: {
              fontSize: getCSSVariableValue("--el-font-size-medium"),
              color: getCSSVariableValue("--bs-dark"),
              offsetY: 30,
            },
            value: {
              offsetY: -20,
              fontSize: "24px",
              color: getCSSVariableValue("--bs-primary"),
              formatter: function (val) {
                return val + "%";
              },
            },
          },
        },
      },
      colors: [getCSSVariableValue("--bs-primary")],
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          shadeIntensity: 0.4,
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 53, 91],
        },
      },
      grid: {
        padding: {
          bottom: 20,
        },
      },
      stroke: {
        dashArray: 4,
      },
      labels: ["Note globale"],
    });

    const finishedSorting = (a, b) => {
      if (props.diagnostic && props.diagnostic.finished) {
        if (a.factor.id < b.factor.id) {
          return -1;
        } else {
          return 1;
        }
      } else {
        if (!a.result && b.result) {
          return -1;
        }
        return 1;
      }
    };

    const setActiveFactor = (factorId) => {
      emit("set-active-factor", factorId);
    };

    const cancelFactorResult = () => {
      emit("cancel-factor-result");
    };

    const finishDiagnostic = () => {
      if (props.diagnostic) {
        store
          .dispatch(Actions.UPDATE_DIAGNOSTIC, {
            id: props.diagnostic.id,
            finished: true,
          })
          .then(() => {
            router.push({
              name: "diagnostic-results",
              params: { diagnosticId: props.diagnostic?.id },
            });
            notify({
              text: "Diagnostic finalisé",
              color: "success",
              duration: 3000,
            });
            router.push({
              name: "diagnostic-results",
              params: { diagnosticId: props.diagnostic?.id },
            });
          });
      }
    };

    const deleteDiagnostic = () => {
      if (props.diagnostic) {
        store
          .dispatch(Actions.DELETE_DIAGNOSTIC, {
            id: props.diagnostic.id,
          })
          .then(() => {
            router.push({ name: "diagnostic-list" });
          });
      }
    };

    const getColor = (number) => {
      if (props.diagnostic && !props.diagnostic.finished) {
        return "#82b624";
      }
      return correctResultColor(number);
    };

    return {
      scoreOptions,
      factorsWithResults,
      resultScore,
      finishedSorting,
      setActiveFactor,
      cancelFactorResult,
      finishDiagnostic,
      deleteDiagnostic,
      getColor,
      parseDate: parseDate,
      cardSubtitle,
    };
  },
  watch: {},
});
