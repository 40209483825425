
import { notify } from "@/core/helpers/globalJaya.ts";
import { computed, defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import FactorList from "@/components/diagnostic/FactorList.vue";
import Factor from "@/components/diagnostic/Factor.vue";
import { useRoute, useRouter } from "vue-router";
import { Diagnostic } from "@/store/modules/DiagnosticsModule";
import scrollTop from "@/layout/extras/ScrollTop.vue";
import OfflineService from "@/core/services/OfflineService";

export default defineComponent({
  name: "diagnostic",
  components: {
    FactorList,
    Factor,
  },
  setup: function () {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    let selectedFactorId = ref<number>(-1);
    let diagnosticId = ref<number>(
      +router.currentRoute.value.params.diagnosticId
    );
    let diagnostic = ref<Diagnostic | null>(null);
    let path = route.path;

    const factors = computed(() => store.getters.getFactors);

    onMounted(() => {
      store
        .dispatch(Actions.FETCH_EXPLOITATIONS)
        .then(() => {
          store.dispatch(Actions.FETCH_QUIZ).then(() => {
            store.dispatch(Actions.FETCH_DIAGNOSTICS).then(() => {
              if (
                store.getters.getDiagnostics.some(
                  (diagnostic) => diagnostic.id === diagnosticId.value
                )
              ) {
                diagnostic.value = store.getters.getDiagnostics.find(
                  (diagnostic) => diagnostic.id === diagnosticId.value
                ) as Diagnostic;
                setCurrentPageTitle("Diagnostic");
                if (route.hash) {
                  setActiveFactor(Number(route.hash.replace("#", "")));
                }
              } else {
                router.push({
                  name: "404",
                });
              }
            });
          });
        })
        .catch(() => {
          router.push({ name: "diagnostic-list" });
          notify({
            text: "Une erreur est survenue. Veuillez réessayer ultérieurement",
            color: "error",
          });
        });
    });

    const updateResults = (event) => {
      window.scrollTo(0, 0);
      if (diagnostic.value) {
        let previousResult = diagnostic.value.results.find(
          (result) => result.level_details.factor === selectedFactorId.value
        );
        if (previousResult) {
          store.dispatch(Actions.UPDATE_DIAGNOSTIC_RESULT, {
            id: previousResult.id,
            diagnostic: diagnosticId.value,
            level: event.resultLevel,
          });
        } else {
          store
            .dispatch(Actions.ADD_DIAGNOSTIC_RESULT, {
              diagnostic: diagnosticId.value,
              level: event.resultLevel,
            })
            .then(() => {
              notify({
                text: "Le niveau du facteur de biodiversité a bien été pris en compte",
                color: "success",
                duration: 3000,
              });
            })
            .catch(() => {
              OfflineService.syncCache();
              router.push({ name: "homepage" });
            });
        }
        selectedFactorId.value = -1;
      }
    };

    const setActiveFactor = (factorId) => {
      selectedFactorId.value = factorId;
      router.push({ path: path, hash: "#" + selectedFactorId.value });
    };

    const cancelFactorResult = () => {
      router.push({ path: path });
      selectedFactorId.value = -1;
    };

    return {
      selectedFactorId,
      diagnosticId,
      factors,
      diagnostic,
      setActiveFactor,
      updateResults,
      cancelFactorResult,
    };
  },
  watch: {
    "$route.hash"(val) {
      if (!val) {
        this.selectedFactorId = -1;
      } else {
        this.selectedFactorId = Number(val.substring(1));
      }
    },
  },
});
