
import { defineComponent, onMounted, ref, computed, PropType } from "vue";
import CardTitleJaya from "@/components/global/CardTitleJaya.vue";
import { deepCopy, openLinkAgriBest } from "@/core/helpers/globalJaya";
import VideoComponent from "@/components/global/VideoComponent.vue";
import { Factor } from "@/store/modules/DiagnosticsModule";

export default defineComponent({
  name: "facteur",
  methods: { openLinkAgriBest },
  emits: ["cancelFactor", "newResult"],
  props: {
    factor: { type: Object as PropType<Factor>, required: true },
    editable: { type: Boolean, default: false },
    result: { type: Object },
  },
  components: {
    VideoComponent,
    CardTitleJaya,
  },
  setup(props, { emit }) {
    let factorLevelChoice = ref<number>(-1);
    let pauseVideo = ref<boolean>(false);
    const setPause = (value) => {
      pauseVideo.value = value;
    };
    onMounted(() => {
      if (props.result) {
        factorLevelChoice.value = props.result.level;
      }
      let myModalEl = document.getElementById("video");
      myModalEl?.addEventListener("hidden.bs.modal", function () {
        setPause(true);
      });
    });

    const sortedFactorLevels = computed(() => {
      let factorLevelsCopy = deepCopy(props.factor.levels);
      return factorLevelsCopy.sort((a, b) => (a.level < b.level ? -1 : 1));
    });

    const validateResult = () => {
      if (factorLevelChoice.value >= 0) {
        emit("newResult", {
          resultLevel: factorLevelChoice.value,
        });
      }
    };
    const cancelResult = () => {
      emit("cancelFactor");
    };

    return {
      factorLevelChoice,
      sortedFactorLevels,
      validateResult,
      cancelResult,
      setPause,
      pauseVideo,
    };
  },
});
